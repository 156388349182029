.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}
.heading-hero{
  font-size: 2.6rem !important;
  color: #000000 !important;
}
.sub-heading-hero {
  font-size: 1.75rem !important;
  text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
}
.hero-p{
  color: #6C757D !important;
}
.bg-green-sd{
  background: rgba(125, 180, 192, 0.3);
}
.bg-light-purple-sd{
  background: rgba(197, 179, 227, 0.2);
}
.bg-purple-sd{
  background: rgba(197, 179, 227, 0.3);
}
.bg-dark-blue-sd{
  background: rgba(52, 71, 103, 0.3);
}
.bg-light-blue-sd{
  background: rgba(39, 154, 241, 0.2);
}
.bg-light-blue-new-sd{
  background: rgba(0, 31, 63, 0.3);
}
.bg-light-orange-sd{
  background: rgba(224, 47, 24, 0.2);
}

.bg-support-sd{
  background: rgba(112, 81, 87, 0.2);
}
.product-des p{
  font-size: 0.875rem !important;
}
.product-main-des h1, .product-main-des h2, .product-main-des h3, .product-main-des h4{
  font-family: "Poppins-light", sans-serif;
  font-size: 1.5rem;
  color: rgb(52, 71, 103);
  font-weight: 300;
}
.product-main-des h2.small{
  color: rgb(52, 71, 103);
}
.products-feed ul {
  list-style: none !important;
  padding-left: 0 !important;
}
.products-feed li {
  list-style: none !important;
}

.sd-green-form, .grow-button{
  border-color: #7DB4C0 !important;
}

.hover-green:hover {
  background: red !important
}
.sd-body {
  color: #6C757D !important;
}
.sd-hover:hover{
  background-color: rgba(125, 180, 192, 0.3) !important;
}
.sd-service h4{
  font-size: 1.2rem !important;
}
.sd-hover:hover p{
  color: #6C757D !important;
}
.navbar-collapse.show{
  background: rgba(125, 180, 192, 1) !important;
  z-index: 1 !important;
}
.modal-backdrop{
  z-index: 1 !important;
}
.pagination .page-item.active .page-link{
  color: #ffffff !important;
  font-weight: 600 !important;
}
.modal-backdrop.show {
  display: none !important;
}
.sidebar-form-input .form-control {
  color: #ffffff !important;
}


@media (max-width: 768px){
  .home-hero-container{
    background-position: center center !important;
    height: 100vh !important;
    padding-top: 4rem;
    padding-bottom: 0rem;
  }
  .home-hero-content{
    padding-top: 4rem !important;
    z-index: 0 !important;
    align-self: flex-start !important;
  }
  .hero-p{
    text-shadow: 0.5px 0.5px 0.5px rgba(255, 255, 255, 0.7) !important;
  }
  .input-footer-sd{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .sign-up-sd-footer{
    border-top: 1px solid rgba(0,0,0,0.3) !important;
    padding-top: 2rem;
  }
  .home-hero-content {
    padding-top: 10rem !important;
    z-index: 0 !important;
    align-self: flex-end !important;
    margin-bottom: 1rem !important;
  }

  .product-single .home-hero-container{
    background-position: center center !important;
    height: 10vh !important;
    padding-top: 3rem;
    padding-bottom: 0rem;
  }

  .product-single  .home-hero-content {
    padding-top: 0rem !important;
    z-index: 0 !important;
    align-self: flex-end !important;
    margin-bottom: 1rem !important;
  }
}
